<template>
  <div>
    <vue-good-table
      :columns="columns"
      :rows="reports"
      @on-row-click="printReport"
    />

    <!-- modal -->
    <b-modal ref="my-modal" hide-footer title="Using Component Methods">
      <div class="d-block text-center">
        <h3>Agregado para exclusão</h3>
        <b-row>
          <!-- situacaoRegistro -->
          <b-col ols="12" lg="12">
            <b-form-group
              :label="
                !idAgregado ? 'Selecione uma opção' : 'Agregado selecionado'
              "
              label-for="v-situacao"
            >
              <v-select
                v-model="idAgregado"
                label="nome"
                :options="comboDepedentesPorTipo"
                rule
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <b-row>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          class="col-12 mt-1"
          variant="outline-primary"
          block
          :disabled="!idAgregado"
          @click="showMsgBoxTwo"
        >
          Continua
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          class="col-12 mt-1"
          variant="outline-danger"
          block
          @click="hideModal"
        >
          Cancelar
        </b-button>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { VueGoodTable } from 'vue-good-table'
import {
  BModal,
  BButton,
  BCol,
  BRow,
  BFormGroup,
  BAlert,
  BCardText
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import mixinsGlobal from '@/mixins'

export default {
  name: 'ReportAssociantes',
  components: {
    VueGoodTable,
    BCardCode,
    BButton,
    BModal,
    BAlert,
    BCardText,
    BCol,
    BRow,
    BFormGroup,
    vSelect
  },
  directives: {
    Ripple
  },
  mixins: [mixinsGlobal],
  computed: {
    ...mapState('combosModule', {
      comboDepedentesPorTipo: (state) => state.comboDepedentesPorTipo
    })
  },
  data() {
    return {
      idAgregado: null,
      reports: [],
      columns: [
        {
          label: 'Relatório',
          field: 'nomeAmigavel'
        }
      ],
      pdfTeste: 'YOUR_PDF',
      report: null
    }
  },
  mounted() {
    this.$store
      .dispatch('reportModule/listagemRelatorios', this.$route.params.id)
      .then((response) => {
        console.log('--------', response)
        this.reports = response.data
      })
  },
  methods: {
    printReport(params) {
      this.report = {}
      this.report = {
        nome: params.row.nome,
        valor: [parseInt(this.$route.params.id)]
      }
      if (this.report.nome === 'solicitacao_de_exclusao_agregado_neos') {
        this.showModal()
        return
      }
      this.$store
        .dispatch('reportModule/printReport', this.report)
        .then((response) => {
          if (response.error) {
            this.showMessageSuccess('Limite Excedido', response.message)
          }
          this.openNewAbaBase64(response.data)
        })
    },
    async showModal() {
      await this.$store.dispatch(
        'combosModule/loadComboDepedenteAssociadoTipo',
        this.$route.params.id
      )
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    showMsgBoxTwo() {
      this.boxTwo = ''
      this.$bvModal
        .msgBoxConfirm(
          'Deseja emitir o relatório de exclusão para este agregado.',
          {
            title: 'Confirmação',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Sim',
            cancelTitle: 'Não',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true
          }
        )
        .then((value) => {
          this.boxTwo = value
          if (value) {
            this.printRoportExclusao()
            this.hideModal()
          }
        })
    },
    printRoportExclusao() {
      this.report.valor.push(this.idAgregado.id)
      this.$store
        .dispatch('reportModule/printReport', this.report)
        .then((response) => {
          this.openNewAbaBase64(response.data)
        })
    }
  }
}
</script>
